<div class="session" id="reference">
    <div class="container">
        <div class='row'>
            <div class='col-md-12 reference'>
                <h3 class="item--title st-line-left text-white">
                    <span ><i></i>NOS REFERENCES </span>
                    <p class="text-white">Quelques-uns de nos clients</p>
                </h3>
            </div>
        </div>
        <div class='row'>
            <div class='col-md-12'>
                <div class="carousel slide media-carousel" id="media">
                    <div class="carousel-inner">
                        <div class="item  active">
                            <div class="row">
                                <div class=" col-md-3 mb-3 mb-lg-3 " data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/1.png" class="img-fluid"
                                             style="padding-top: 40px">
                                    </div>
                                </div>
                                <div class=" col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/2.png" class="img-fluid">
                                    </div>
                                </div>
                                <div class=" col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/3.png" class="img-fluid">
                                    </div>
                                </div>
                                <div class=" col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/4.png" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item  ">
                            <div class="row">
                                <div class="item col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/5.png" class="img-fluid"
                                             style="padding-top: 40px">
                                    </div>
                                </div>
                                <div class="item col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/6.png" class="img-fluid"
                                             style="padding-top: 20px">
                                    </div>
                                </div>
                                <div class="item col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/7.png" class="img-fluid"
                                             style="padding-top: 10px">
                                    </div>
                                </div>
                                <div class="item col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/8.png" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item  ">
                            <div class="row">
                                <div class="item col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/9.png" class="img-fluid"
                                             style="padding-top: 30px">
                                    </div>
                                </div>
                                <div class="item col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/10.png" class="img-fluid">
                                    </div>
                                </div>
                                <div class="item col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/11.png" class="img-fluid"
                                             style="padding-top: 30px">
                                    </div>
                                </div>
                                <div class="item col-md-3 mb-3 mb-lg-3" data-aos="fade-up">
                                    <div class="pad15">
                                        <img src="{{img}}assets/images/partenaire/12.png" class="img-fluid"
                                             style="padding-top: 10px">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a class="left carousel-control" href="#media" role="button" data-slide="prev">
                        <span class="glyphicon glyphicon-chevron-left " aria-hidden="true"></span>
                        <span class="sr-only">Précédente</span>
                    </a>

                    <a class="right carousel-control" href="#media" role="button" data-slide="next">
                        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                        <span class="sr-only">Prochain</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
