<div class=" session " id="apropos">
    <div class="container ">
        <div class="quiSommesnous box-shadow white ">
            <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-7">
                    <div class="card-accueil row">
                        <div class="col-md-12 ">


                            <div class="apropos">
                                <h3 class="item--title st-line-left1"><span><i></i>
                                La satisfaction cliente au cœur de notre démarche</span>
                                </h3>
                                <div class="item--desc text-justify" style="padding: 20px;padding-left:82px;">

                                    Le groupe Modelis s'est créé et se développe autour d'une unique motivation : répondre aux besoins de nos clients. C'est pourquoi,
                                    il s'est adapté au fil des années en spécialisant ses équipes autour des
                                    trois domaines prioritaires : la topographie, la géomatique et le génie civil.<br><br>
                                    La base du groupe Modelis se trouve en Côte d'ivoire. Nous restons attachés à cela et ambitionnons de créer une multi-nationale ancrée dans les valeurs et la culture africaine.<br><br>
                                    Partout où le groupe Modelis ira, le client sera toujours au cœur de notre attention. C'est notre motivation et celle de nos équipes au quotidien : offrir la plus grande valeur ajoutée à nos clients.<br><br>
                                    Nous espérons collaborer avec vous sur vos projets et vous apporter toute notre expertise.

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="item--holder wow fadeInUp"
                                         style="visibility: visible; animation-name: fadeInUp;">
                                        <h2><i class="fa fa-angle-double-right"></i>
                                            Notre Vision
                                        </h2>
                                        <div class="item--desc">Nous pensons que la géomatique peut révolutionner votre
                                            compréhension du monde.
                                        </div>
                                    </div>
                                    <div class="item--holder wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <h2>
                                            <i class="fa fa-angle-double-right"></i>Notre Mission
                                        </h2>
                                        <div class="item--desc">
                                            Nous démocratisons l’accès à l’information géographique.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item--holder wow fadeInUp"
                                         style="visibility: visible; animation-name: fadeInUp;">
                                        <h2><i class="fa fa-angle-double-right"></i>
                                            Nos valeurs
                                        </h2>
                                        <div class="item--desc">
                                            Expertise, objectivité , intégrité et respect des autres
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5 wow fadeInUp " style="margin:auto;visibility: visible; animation-name: fadeInUp;">
                    <div class="">
                        <img
                                style="
                            height: auto;
                            max-width: 100%;
                            border: none;
                            -webkit-border-radius: 0;
                            border-radius: 0;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            "
                                class="img-responsive img-center"
                                src="{{img}}assets/images/quisommesnous.png">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
