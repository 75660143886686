<div class="session" id="equipes">
  <div class="container">
    <div class="col-md-12 realisation">
      <h3 class="item--title st-line-left">
        <span><i></i>
          NOTRE EQUIPE
        </span>
        <p class="text-dark ">

        </p>
      </h3>
    </div>
    <div class="gem-team row inline-row gem-team-style-6">
      <div class="col-md-4 col-sm-6 col-xs-12 inline-column" *ngFor="let equipe of equipes">
        <div
          class="team-person default-background post-24337 thegem_team_person type-thegem_team_person status-publish has-post-thumbnail">
          <div class="team-person-hover">
            <div class="team-person-box clearfix">
              <div class="team-person-box-left">
                <div class="team-person-image">
                    <span>
                      <img src="{{equipe.image}}"
                           class="img-responsive wp-post-image"/>
                    </span>
                </div>
              </div>
              <div class="team-person-box-right">
                <div class="team-person-info">
                  <p class="team-person-name styled-subtitle"
                       [innerHTML]="equipe.prenom +' '+ equipe.nom">
                  </p>
                  <div class="team-person-position date-color" [innerHTML]="equipe.poste"></div>

                </div>
              </div>
            </div>
          </div>
          <!-- <a class="team-person-link" (click)="detailEquipe(equipe.id)" target="_self"></a> -->
        </div>
      </div>
    </div>

    <!-- <div class="gem-team row inline-row gem-team-style-6">
      <div class="col-md-4 col-sm-6 col-xs-12 "style="display: flex; justify-content: space-between; width: 100%;">
        <div class="team-person default-background post-24337 thegem_team_person type-thegem_team_person status-publish has-post-thumbnail">
          <div class="team-person-hover">
            <div class="team-person-box clearfix">
              <div class="team-person-box-left">
                <div class="team-person-image">
                  <span>
                    <img src="assets/images/dg.png" class="img-responsive" />
                  </span>
                </div>
              </div>
              <div class="team-person-box-right">
                <div class="team-person-info">
                  <p class="team-person-name styled-subtitle">IRIE BI IRIE FABRICE
                  </p>
                  <p class="team-person-name styled-subtitle">PDG
                  </p>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div
          class="team-person default-background post-24337 thegem_team_person type-thegem_team_person status-publish has-post-thumbnail">
          <div class="team-person-hover">
            <div class="team-person-box clearfix">
              <div class="team-person-box-left">
                <div class="team-person-image">
                  <span>
                    <img src="assets/images/personne/default.jpg" class="img-responsive" />
                  </span>
                </div>
              </div>
              <div class="team-person-box-right">
                <div class="team-person-info">
                  <p class="team-person-name styled-subtitle">RIE BI EDWIGE
                  </p>
                  <p class="team-person-name styled-subtitle">DGA
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div
        class="team-person default-background post-24337 thegem_team_person type-thegem_team_person status-publish has-post-thumbnail">
        <div class="team-person-hover">
          <div class="team-person-box clearfix">
            <div class="team-person-box-left">
              <div class="team-person-image">
                <span>
                  <img src="assets/images/personne/default.png" class="img-responsive" />
                </span>
              </div>
            </div>
            <div class="team-person-box-right">
              <div class="team-person-info">
                <p class="team-person-name styled-subtitle">LUKAMA URSULE
                </p>
                <p class="team-person-name styled-subtitle">Responsable RH
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      </div>


    </div> -->

    <!-- partie 2 -->
    <!-- <div class="gem-team row inline-row gem-team-style-6"
      style="display: flex; justify-content: space-between; margin-bottom: 35px;"> -->
      <!-- <div class="col-md-4 col-sm-6 col-xs-12 inline-column" *ngFor="let equipe of [1,2]"> -->
      <!-- <div
        class="team-person default-background post-24337 thegem_team_person type-thegem_team_person status-publish has-post-thumbnail">
        <div class="team-person-hover">
          <div class="team-person-box clearfix">
            <div class="team-person-box-left">
              <div class="team-person-image">
                <span>
                  <img src="assets/images/default.png" class="img-responsive" />
                </span>
              </div>
            </div>
            <div class="team-person-box-right">
              <div class="team-person-info">
                <p class="team-person-name styled-subtitle">SEVEDE AMADOU
                </p>
                <p class="team-person-name styled-subtitle">A définir
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="team-person default-background post-24337 thegem_team_person type-thegem_team_person status-publish has-post-thumbnail">
        <div class="team-person-hover">
          <div class="team-person-box clearfix">
            <div class="team-person-box-left">
              <div class="team-person-image">
                <span>
                  <img src="assets/images/personne/default.png" class="img-responsive" />
                </span>
              </div>
            </div>
            <div class="team-person-box-right">
              <div class="team-person-info">
                <p class="team-person-name styled-subtitle">TRA IRIE FRANCK
                </p>
                <p class="team-person-name styled-subtitle">DGA
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="team-person default-background post-24337 thegem_team_person type-thegem_team_person status-publish has-post-thumbnail">
        <div class="team-person-hover">
          <div class="team-person-box clearfix">
            <div class="team-person-box-left">
              <div class="team-person-image">
                <span>
                  <img src="assets/images/personne/default.png" class="img-responsive" />
                </span>
              </div>
            </div>
            <div class="team-person-box-right">
              <div class="team-person-info">
                <p class="team-person-name styled-subtitle">GOHI LOU SANDRINE
                </p>
                <p class="team-person-name styled-subtitle">Responsable RH
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div> -->

  </div>
</div>
