<div class="footer">
  <div class="ftco-footer box-shadow">
    <div class="container">
      <div class="row ">
        <div class="col-md-3">
          <div class="ftco-footer-widget mb-5 ">
            <h2 class="ftco-heading-2 logo" style="margin-bottom: 0"><span><a href="#accueil">
                                    <img src="{{img}}assets/images/MODELIS-Groupe.png" class="img-responsive bg-white"
                                         alt="">
                                </a></span>
            </h2>
          </div>
          <div class="ftco-footer-widget mb-5 text-center">
            <h2 class="ftco-heading-2">Contacts</h2>
            <div class="block-23 mb-3">
              <ul>
                <li><a href="#contact"><span class="fa fa-envelope"></span><span
                  class="text"> contact@modelis-group.com</span></a></li>
              </ul>
            </div>
            <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-3"
                style="width: 100%">
              <li>
                <a target="_blank" href="https://www.facebook.com/Modelis-GROUP-107276611170446/" target="_blank"
                   class="p-3"><span class="icon fa fa-linkedin"></span></a>
              </li>
              <li>
                <a target="_blank" href="https://www.linkedin.com/company/modelis-group/" target="_blank"
                   class="p-3">
                  <span class="icon fa fa-facebook"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-9">
          <div class="row">
            <div class="col-md-3">
              <div class="ftco-footer-widget mb-5 ml-md-4 ">
                <h2 class="ftco-heading-2"><a class="ion-ios-arrow-round-forward mr-2" href="#page-top">Accueil</a></h2>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ftco-footer-widget mb-5 ml-md-4 ">
                <h2 class="ftco-heading-2">A propos</h2>
                <ul class="submenu">
                  <li><a class="page-scroll" href="#apropos">Présentation du groupe</a></li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ftco-footer-widget mb-5 ml-md-4">
                <h2 class="footer_title">
                  Expertises
                </h2>
                <ul class="submenu">
                  <li><a class="page-scroll" href="#expertise">Présentation de l'expertise du groupe</a></li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ftco-footer-widget  ml-md-4">
                <h2 class="ftco-heading-2">Réalisations</h2>
                <ul class="submenu">
                  <li><a class="page-scroll" href="#realisation">Liste de nos projets et produits</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">

              <div class="ftco-footer-widget  ml-md-4">
                <h2 class="ftco-heading-2">Références</h2>
                <ul class="submenu">
                  <li><a class="page-scroll" href="#reference">Liste de nos partenaires</a></li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ftco-footer-widget ml-md-4">
                <h2 class="ftco-heading-2">Equipe</h2>
                <ul class="submenu">
                  <li><a class="page-scroll" href="#equipes">Nos collaborateurs</a></li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ftco-footer-widget ml-md-4">
                <h2 class="ftco-heading-2">Blog</h2>
                <ul class="submenu">
                  <li><a class="page-scroll" href="#blog">Consulter le blog</a></li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ftco-footer-widget mb-5 ml-md-4">
                <h2 class="ftco-heading-2">Autres liens</h2>
                <ul class="submenu">
                  <li><a target="_blank" href="http://www.easytopo.fr/">Logiciel EasyTopo</a></li>
                </ul>
                <ul class="submenu">
                  <li><a target="_blank" href="http://formation.easytopo.fr/">Site de Formation MODELIS</a></li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ftco-footer-widget mb-5 ml-md-4">
                <h2 class="ftco-heading-2">Liens utiles</h2>
                <ul class="submenu">
                  <li><a target="_blank" href="http://shop.modelis-gis.com/">Site de vente en ligne</a></li>
                </ul>
                <ul class="submenu">
                  <li><a target="_blank" href="http://catalogue.modelis-gis.com/">Catalogue en ligne</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 text-center">
          <p>
            © 2020 <i class="icon-heart text-danger" aria-hidden="true"></i>
            <a target="_blank" href="http://modelis-group.com"> Modelis-group </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="static">
    <div class="header-top_area ">
      <div class=" short_contact_list">
        <ul>
          <li><a target="_blank" href="http://modelisgroup.com/">Modelis-Group</a></li>
          <li><a target="_blank" href="http://modelis-gc.com/">Modelis-Gc</a></li>
          <li><a target="_blank" href="http://modelis-gis.com/">Modelis-Gis</a></li>
          <li><a target="_blank" href="http://modelis-tech.com/">Modelis-Tech</a></li>
          <li><a target="_blank" href="http://modelis-france.com/">Modelis-France</a></li>
          <li><a target="_blank" href="http://modelis-mali.com/">Modelis-Mali</a></li>
          <li><a target="_blank" href="http://modelis-senegal.com/">Modelis-Sénégal</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
