import {Component, OnInit} from '@angular/core';
import {Variable} from '../../service/Variable';
import {NgForm} from '@angular/forms';
import {Contacts} from '../../service/class/Contacts';
import {ContactsController} from '../../service/controller/contacts.controller';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
    contacts = new Contacts();
    loading = false;
    error;
    errormes = 'une erreur s\'est produite lors de l\'envoi de votre message. veuillez essayer à nouveau plus tardvot';
    success;
    successmes = 'votre email est bien reçu par notre équipe';
    infosListes;
    form: NgForm;

    constructor(private variable: Variable, private controller: ContactsController) {
    }

    img = this.variable.BASE_IMG;

    ngOnInit(): void {
    }

    onSubmit(form: NgForm) {
        this.controller.mail(
            this.contacts.nom,
            this.contacts.prenom,
            this.contacts.email,
            this.contacts.telephone,
            this.contacts.objet,
            this.contacts.message
        )
            .subscribe(response => {
                if (response == true) {
                    form.reset();
                    this.contacts.nom = '';
                    this.contacts.email = '';
                    this.contacts.objet = '';
                    this.contacts.prenom = '';
                    this.contacts.telephone = '';
                    this.contacts.message = '';
                    this.success = true;
                    this.error = false;
                } else {
                    this.success = false;
                    this.error = true;
                }
            });
    }
}
