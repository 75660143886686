<div style="overflow: hidden;"></div>
<header class="modelis-header ">
    <div class="modelis-head bg-primary">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-md-4 col-sm-4 text-left" style="padding-top: 3px ">
                    <div class="modelis-head-text text-white">
                        BIENVENUE SUR MODELIS-GROUP
                    </div>
                </div>
                <div class="col-xl-8 col-md-8 col-sm-8 ">
                    <ul class="list-inline social-icon ">
                        <li>
                            <a href="https://www.facebook.com/Modelis-GROUP-107276611170446/" target="_blank">
                                <span class="fa fa-facebook"></span></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/modelis-group/" target="_blank">
                                <span class="fa fa-linkedin"></span>
                            </a>

                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
    <div class="modelis-header-middle main-menu-display">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="ct-header-branding">
                        <a class="logo-dark" href="http://modelisgroup.com">
                            <img src="{{img}}assets/images/MODELIS-Groupe.png"
                                 class="img-logo img-responsive" style="
                                     display: block; width: 244px; margin:auto;height: auto"></a>
                    </div>
                </div>
                <div class="col-md-9 center-block ">
                    <div class="modelis-header-holder">
                        <div class="modelis-header-info-item modelis-header-call">
                            <div class="h-item-icon ">
                                <i class="icon fa fa-phone text-gradient"></i>
                            </div>
                            <div class="h-item-meta">
                                <label>Pour nous appeler: </label>

                                <span>+225 27 22 47 64 59</span>

                            </div>
                            <a href="tel:+123456789" target="_self" class="h-item-link"></a>
                        </div>
                        <div class="modelis-header-info-item modelis-header-mail">
                            <div class="h-item-icon">
                                <i class="icon fa fa-envelope text-gradient"></i>
                            </div>
                            <div class="h-item-meta">
                                <label>Pour nous écrire: </label>
                                <span>contact@modelisgroup.com</span>
                            </div>
                            <a href="mailto:info@consultio.com" target="_self" class="h-item-link"></a>
                        </div>
                        <div class="modelis-header-info-item modelis-header-address">
                            <div class="h-item-icon">
                                <i class="icon fa fa-map-marker text-gradient"></i>
                            </div>
                            <div class="h-item-meta">
                                <label>Cocody Abatta, Agence SODECI </label>
                                <span> 28 BP 1402 ABIDJAN 28 Côte d'Ivoire</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<nav class="navbar navbar-default  " role="navigation">
    <div class="navbar-header page-scroll">
        <a class="logo-dark main-menu" href="http://modelisgroup.com">
            <img src="{{img}}assets/images/MODELIS-Groupe.png"
                 class="img-logo img-responsive" style="float: left;
                                     display: block; width: 244px; margin:auto;height: auto"></a>
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
                aria-expanded="false" aria-controls="navbar" style="top: 16px;">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
    </div>
    <div class="container">
        <div class="navbar-collapse collapse " id="navbar">
            <div class="row">
                <div class=" col-lg-10  col-md-10 col-sm-9 col-xs-9   left">

                    <ul class="nav navbar-nav ">
                        <li style="padding: 0px;margin: 0px;" class="main-menu-2">
                            <a class="logo-dark  " href="http://modelisgroup.com" style="height: 65px;">
                                <img src="{{img}}assets/images/MODELIS-Groupe.png"
                                     class="img-logo img-responsive" style="float: left;
                                     ;height: 65px;"></a>
                        </li>
                        <li><a class="page-scroll" href="#page-top">Accueil</a></li>
                        <li><a class="page-scroll" href="#apropos">A propos</a></li>
                        <li><a class="page-scroll" href="#expertise">Expertises</a></li>
                        <li><a class="page-scroll" href="#realisation">Réalisations</a></li>
                        <li><a class="page-scroll" href="#equipes">Equipe</a></li>
                        <li><a class="page-scroll" href="#reference">Références</a></li>
                        <li><a class="page-scroll" href="#blog">Blog</a></li>
                        <li style="display: none!important;"><a class="page-scroll" href="#contact">contacts</a></li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3  right main-menu-display-2">
                    <a class="btn btn-primary page-scroll" href="#contact">Contact<i
                            class="ct-arrow-right arrow-white"></i></a>
                </div>
            </div>
        </div>
    </div>
</nav>
<app-slider></app-slider>
