<div class="session" id="realisation">
    <div class="container">
        <div class="col-md-12 realisation">
            <h3 class="item--title st-line-left">
                <span><i></i>
                       NOS REALISATIONS
                </span>
                <p class="text-dark ">
                    Quelques-unes de nos réalisations
                </p>
            </h3>
        </div>
        <div class="ct-grid ct-portfolio-grid1">
            <div class="ct-grid-overlay"></div>
            <div class="grid-filter-wrap">
                <span class="filter-item active" data-target="all">Tout</span>

                <span class="filter-item" data-target="collecter">
                    Collecter               </span>

                <span class="filter-item" data-target="traiter">
                    Traiter                </span>

                <span class="filter-item " data-target="diffuser">
                    Diffuser               </span>
                <span class="filter-item " data-target="construire">
                    Construire               </span>
            </div>

            <div class="ct-grid-inner ct-grid-masonry row animate-time" data-gutter="15">

                <!-- collecter -->
                <div data-status="collecter" class="row disparaitre">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12  ">
                        <div class=" col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4 ">
                            <div class="grid-item  ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/collecter/ingenierie.png"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title"> collecte de données electriques CI energie</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/collecter/drone.png" alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">collecte de données par drone</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/collecter/ventes.png" alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Vente et location d’appareil</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/collecter/maintenance.png"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Maintenance et réparation d’appareil</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- fin collecter -->

                <!-- traiter -->
                <div data-status="traiter" class="row disparaitre">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item  ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/traiter/easycadastre.png"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">EasyCadastre</h3>
                                        </div>
                                        <div class="item--readmore">
                                            <a href="https://cadastrenational.dgi.gouv.ci/" target="_blank"><i
                                                    class="fa fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item  ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/traiter/easytopo.png"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">EasyTopo</h3>
                                        </div>
                                        <div class="item--readmore">
                                            <a href="http://www.easytopo.fr/" target="_blank"><i
                                                    class="fa fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4 ">
                            <div class="grid-item  ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/traiter/image_2020_11_19T12_17_27_127Z.png"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Cadastre électrique
                                                Cote d'ivoire </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item  ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/traiter/logiciel.png"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title"> logiciel </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- fin traiter -->

                <!-- diffuser -->
                <div data-status="diffuser" class="row disparaitre">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div  class="grid-item  ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/diffuser/Projet_Mali_MODELIS.png"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Cartographie des orpailleurs au Mali </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div  class="grid-item ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/diffuser/bag2shop.png" alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Site internet Bag2shop</h3>
                                        </div>
                                        <div class="item--readmore">
                                            <a href="https://bag2shop.fr" target="_blank"><i class="fa fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4 ">
                            <div  class="grid-item ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/diffuser/firca.png" alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Site internet Firca </h3>
                                        </div>
                                        <div class="item--readmore">
                                            <a href="https://firca.ci/" target="_blank"><i class="fa fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div  class="grid-item ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"
                                             src="{{img}}assets/images/realisation/diffuser/formation.JPG"
                                             alt="theme-01"
                                             title="theme-01"></div>
                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Formation des étudiants de l'école LBTP</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- fin diffuser -->

                <!--construire-->
                <div data-status="construire" class="row disparaitre">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/construire/posteelctriqueGuinee.jpg"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Poste électrique GUINEE BISSAU</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/construire/posteelectriqueBingerville.jpg"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Poste électrique Bingerville</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4 ">
                            <div class="grid-item ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/construire/posteelctriqueAgnibilekro.jpg"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Poste électrique Agnibilekro</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6  mb-lg-4 mb-4">
                            <div class="grid-item ">
                                <div class="grid-item-inner wow fadeIn"
                                     style="animation-delay: 100ms; visibility: visible; animation-name: fadeIn;">
                                    <div class="item--featured">
                                        <img  class="img-responsive"

                                             src="{{img}}assets/images/realisation/construire/posteelectriqueagbegourou.jpg"
                                             alt="theme-01"
                                             title="theme-01"></div>

                                    <div class="item--holder">
                                        <div class="item--meta">
                                            <h3 class="item--title">Poste électrique Abengourou</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
