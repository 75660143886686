import { Component, OnInit } from '@angular/core';
import {Variable} from '../../../service/Variable';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  constructor(private variable : Variable) { }
  img = this.variable.BASE_IMG;
  ngOnInit(): void {
  }

}
