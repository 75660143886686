import { Component, OnInit } from '@angular/core';
import {Variable} from '../../service/Variable';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor(private variable : Variable) { }
  img = this.variable.BASE_IMG;
  ngOnInit(): void {
  }

}
