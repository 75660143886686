import { Component, OnInit } from '@angular/core';
import {Variable} from '../service/Variable';

@Component({
  selector: 'app-equipes',
  templateUrl: './equipes.component.html',
  styleUrls: ['./equipes.component.css']
})
export class EquipesComponent implements OnInit {

  constructor(private variable : Variable) { }
  img = this.variable.BASE_IMG;
  equipes=[
    {
      nom:"IRIE BI IRIE ",
      prenom:"FABRICE",
      image:"assets/images/dg.png",
      poste:"PDG"
    },
    {
      nom:"IRIE BI IRIE ",
      prenom:"EDWIGE",
      image:"assets/images/default.png",
      poste:"DGA"
    },
    {
      nom:"LUKAMA ",
      prenom:"URSULE",
      image:"assets/images/personne/ursule.jpeg",
      poste:"Responsable RH"
    },
    {
      nom:"SEVEDE",
      prenom:"AMADOU",
      image:"assets/images/default.png",
      poste:"Responsable Parc Auto"
    },
    {
      nom:"TRA IRIE",
      prenom:"FRANCK",
      image:"assets/images/personne/tra.jpeg",
      poste:"Responsable Financier"
    },
    {
      nom:"GOHI LOU",
      prenom:"SANDRINE",
      image:"assets/images/personne/sandrine.jpeg",
      poste:"Comptable"
    },
    {
      nom:"OURAGA HUGUES",
      prenom:"ERIC",
      image:"assets/images/default.png",
      poste:"Business Developer"
    },
    {
      nom:"KOUASSI STEVEN",
      prenom:"ROLAND",
      image:"assets/images/personne/steven.jpeg",
      poste:"Administrateur Réseau"
    },
    {
      nom:"BILE",
      prenom:"JUDITH",
      image:"assets/images/default.png",
      poste:"Comptable"
    },
    {
      nom:"ATCHE",
      prenom:"AMANDINE",
      image:"assets/images/default.png",
      poste:"Assistante de Direction"
    },
    {
      nom:" N'GORAN",
      prenom:"ANGÈLE",
      image:"assets/images/default.png",
      poste:"A définir"
    }



  ]

  ngOnInit(): void {
  }

}
