<div class="session" id="blog">
    <div class="container">
        <div class="row">
            <div class='col-md-12 reference'>
                <h3 class="item--title ">
                    <span class="text-dark"><i></i>NOTRE BLOG :</span>
                    <p class="text-dark"> Nos récentes activités et ou publications</p>
                </h3>
            </div>
        </div>
        <div class="row">
                        <div class="col-md-6 grid-blog mb-lg-4 mb-4">

                            <div class="item-img">
                                <img class="img-responsive"
                                     src="{{img}}assets/images/blog/agbengourou.jpg">
                            </div>
                            <div class="item-body">
                                <div class="item-content">
                                    <ul class="item-meta text-center">
                                        <li class="item-date">
                                            <i class="fa fa-calendar"></i>2019
                                        </li>
                                        <li class="item-date">
                                            <i class="fa fa-map-marker"></i>CÔTE D’IVOIRE 
                                        </li>
                                    </ul>
                                    <h3 class="entry-title">Poste électrique de Abengourou </h3>
                                </div>
                            </div>
                        </div>

            <div class="col-md-6 grid-blog mb-lg-4 mb-4">
                <div class="item-img">
                    <img class="img-responsive"
                         src="{{img}}assets/images/blog/don.jpg">
                </div>
                <div class="item-body">
                    <div class="item-content">
                        <ul class="item-meta">
                            <li class="item-date">
                                <i class="fa fa-calendar"></i>27 juillet 2019
                            </li>
                            <li class="item-date">
                                <i class="fa fa-map-marker"></i> CÔTE D’IVOIRE 
                            </li>
                        </ul>
                        <h3 class="entry-title">DON à la pouponnière de YOPOUGON ABIDJAN</h3>
                    </div>
                </div>
            </div>

            <!--            <div class="col-md-6 grid-blog mb-lg-4 mb-4">-->

            <!--                <div class="item-img">-->
            <!--                    <img class="img-responsive"-->
            <!--                         src="https://demo.casethemes.net/consultio/wp-content/uploads/2019/11/theme-07-600x389.jpg">-->
            <!--                </div>-->
            <!--                <div class="item-body">-->
            <!--                    <div class="item-content">-->
            <!--                        <ul class="item-meta">-->
            <!--                            <li class="item-date">-->
            <!--                                <i class="fa fa-calendar"></i>-->
            <!--                            </li>-->
            <!--                        </ul>-->
            <!--                        <h3 class="entry-title">Présentation de l 'application du nicad au senegal </h3>-->
            <!--                    </div>-->
            <!--                </div>-->


            <!--            </div>-->


            <!--            2019-->
            <div class="col-md-6 grid-blog mb-lg-4 mb-4">

                <div class="item-img">
                    <img class="img-responsive"
                         src="{{img}}assets/images/blog/visitesiegechantier.jpeg">
                </div>
                <div class="item-body">
                    <div class="item-content">
                        <ul class="item-meta">
                            <li class="item-date">
                                <i class="fa fa-calendar"></i>2019
                            </li>
                            <li class="item-date">
                                <i class="fa fa-map-marker"></i> CÔTE D’IVOIRE 
                            </li>
                        </ul>
                        <h3 class="entry-title">Visite du terrain qui abritera le siège du groupe modelis à Grand
                            bassam </h3>
                    </div>
                </div>


            </div>
            <div class="col-md-6 grid-blog mb-lg-4 mb-4">

                <div class="item-img">
                    <img class="img-responsive"
                         src="{{img}}assets/images/blog/teambuilding.png">
                </div>
                <div class="item-body">

                    <div class="item-content">
                        <ul class="item-meta">
                            <li class="item-date">
                                <i class="fa fa-calendar"></i>25 juillet 2019
                            </li>
                            <li class="item-date">
                                <i class="fa fa-map-marker"></i>CÔTE D’IVOIRE 
                            </li>
                        </ul>
                        <h3 class="entry-title">Team Building 2019<br><br></h3>
                    </div>
                </div>


            </div>
            <!--            2018-->
            <div class="col-md-6 grid-blog mb-lg-4 mb-4">

                <div class="item-img">
                    <img class="img-responsive"
                         src="{{img}}assets/images/blog/intergeo2018.jpeg">
                </div>
                <div class="item-body">
                    <div class="item-content">
                        <ul class="item-meta">
                            <li class="item-date">
                                <i class="fa fa-calendar"></i>2018
                            </li>
                            <li class="item-date">
                                <i class="fa fa-map-marker"></i>ALLEMAGNE
                            </li>
                        </ul>

                        <h3 class="entry-title">Participation du groupe Modelis à Intergeo 2018 </h3>
                    </div>
                </div>


            </div>
            <!--            2016-->
            <div class="col-md-6 grid-blog mb-lg-4 mb-4">

                <div class="item-img">
                    <img class="img-responsive"
                         src="{{img}}assets/images/blog/easytopo.JPG">
                </div>
                <div class="item-body">
                    <div class="item-content">
                        <ul class="item-meta">
                            <li class="item-date">
                                <i class="fa fa-calendar"></i>21 Octobre 2016
                            </li>
                            <li class="item-date">
                                <i class="fa fa-map-marker"></i>Mali
                            </li>
                        </ul>
                        <h3 class="entry-title">Formation sur l'application EasyTopo au Mali</h3>
                    </div>
                </div>


            </div>
            <div class="col-md-6 grid-blog mb-lg-4 mb-4">

                <div class="item-img">
                    <img class="img-responsive"
                         src="{{img}}assets/images/blog/easycadastrecotedivoire.JPG">
                </div>
                <div class="item-body">
                    <div class="item-content">
                        <ul class="item-meta">
                            <li class="item-date">
                                <i class="fa fa-map-marker"></i>16 Aout 2016
                            </li>
                            <li class="item-map">
                                <i class="fa fa-map-marker"></i>CÔTE D’IVOIRE
                            </li>
                        </ul>
                        <h3 class="entry-title">Formation sur l'application Easy Cadastre</h3>
                    </div>
                </div>


            </div>
            <div class="col-md-6 grid-blog mb-lg-4 mb-4">

                <div class="item-img">
                    <img class="img-responsive"
                         src="{{img}}/assets/images/blog/modelischcshangai.JPG">
                </div>
                <div class="item-body">
                    <div class="item-content">
                        <ul class="item-meta">
                            <li class="item-date">
                                <i class="fa fa-map-marker"></i>2 Mars 2016
                            </li>
                            <li class="item-map">
                                <i class="fa fa-map-marker"></i>SHANGHAI
                            </li>
                        </ul>

                        <h3 class="entry-title">Modelis Gis visite son partenaire CHC à SHANGHAI</h3>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
