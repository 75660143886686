<app-accueil></app-accueil>
<app-slider-modelis-group></app-slider-modelis-group>
<app-apropos></app-apropos>
<app-services></app-services>
<app-realisation></app-realisation>
<app-equipes></app-equipes>
<app-references></app-references>
<app-blog></app-blog>
<app-contacts></app-contacts>
<app-footer></app-footer>
